<template>
  <div class="px-2 mt-1">
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debt-solution-settings-bank-accounts-listing' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        LISTING
      </b-nav-item>
      <b-nav-item
        :to="{
          name: 'debt-solution-settings-bank-accounts-deleted',
          params: { state: 'deleted' },
        }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        DELETED
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-info  border-3 border-table-radius px-0"
    >
      <router-view ref="routerView" :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableKey: 0,
    };
  },
  computed: {
    routerName() {
      return this.$route.name;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>